<template>
  <div>
    <b-progress animated variant="success" :value="this.downloadPercentage" height="2rem" width="10rem" />
    <div class="progress-text">{{ this.downloadStatusText }}</div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  data() {
    return {
      downloadPercentage: 0,
      downloadStatusText: '',
    };
  },
  methods: {
    updatePercentageAndText(percentage, text) {
      this.downloadPercentage = percentage;
      this.downloadStatusText = text;
    },
    updateProgressBarBownload(state, completionPercentage) {
      const divider = 2; // two phases = 50% + 50%
      const percentage = parseInt(completionPercentage, 10);

      switch (state) {
        case 'download':
          // First 50%
          this.updatePercentageAndText((percentage / divider), this.$t('progressBar.prepared', { percentage: completionPercentage }));
          break;
        case 'zip':
          // Last 50%
          this.updatePercentageAndText(((percentage / 2) + (100 / divider)), this.$t('progressBar.zipped', { percentage: completionPercentage }));
          break;
        default:
          console.warn('Unknown state, doing nothing');
          break;
      }
    },
    updateProgressBarLinkGeneration(state, completionPercentage) {
      const divider = 3; // tree phases = 33.3% + 33.3% + 33.3%
      const percentage = parseInt(completionPercentage, 10);
      switch (state) {
        case 'download':
          this.updatePercentageAndText((percentage / divider), this.$t('progressBar.prepared', { percentage: completionPercentage }));
          break;
        case 'zip':
          this.updatePercentageAndText(((percentage / divider) + (100 / divider)), this.$t('progressBar.zipped', { percentage: completionPercentage }));
          break;
        case 'upload':
          this.updatePercentageAndText(((percentage / 3) + ((100 / 3) * 2)), this.$t('progressBar.generated', { percentage: completionPercentage }));
          break;
        default:
          console.warn('Unknown state, doing nothing');
          break;
      }
    },
  },
};
</script>
