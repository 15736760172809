import { EnumType } from 'json-to-graphql-query';
import GraphqlApi from '../api/GraphqlApi';
import config from '../config';
import log from '../browserlog';

const DEFAULT_VERSION = config.api.defaultVersion;

const api = new GraphqlApi(config.proxy);

const getAvailableLocales = () => {
  const query = {
    query: {
      locales: {
        locales: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.locales).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const searchEntities = ({ type = 'any', additionalFilters = [], keyword, skip = 0, limit = 10, order = {}, requestType = DEFAULT_VERSION }, cancelToken = null) => {
  // parameters for search
  const parameters = [];

  if (keyword) {
    parameters.push({
      operation: new EnumType('Matches'),
      field: config.typesSearchedFromAnyField.indexOf(type) < 0 ? 'title' : 'any',
      value: keyword,
    });
  }

  additionalFilters.forEach((filter) => {
    // special handling for ID search
    const fieldValue = (filter.id === 'sys.id') ? 'id' : `${filter.filterType}.${filter.id}${filter.filterSubfield ? `.${filter.filterSubfield}` : ''}`;
    parameters.push({
      operation: new EnumType(filter.operation),
      field: fieldValue,
      value: `${filter.value}`, // convert to string
    });
  });

  const query = {
    query: {
      search: {
        __args: {
          searchEntities: {
            parameters,
            limit,
            skip,
            type,
            order,
          },
          requestType: new EnumType(requestType),
        },
        total: true,
        limit: true,
        skip: true,
        results: {
          id: true,
          title: true,
          version: true,
          systemStatus: {
            status: true,
            createdAt: true,
            updatedAt: true,
            approval: {
              id: true,
              status: true,
              requests: {
                id: true,
                revision: true,
                requestType: true,
                status: true,
                requestedBy: true,
                createdDate: true,
                message: true,
                comments: {
                  message: true,
                  commentedBy: true,
                  date: true,
                },
                updatedDate: true,
                approvedBy: true,
                approvedDate: true,
              },
            },
          },
          type: {
            id: true,
            path: true,
          },
          fields: true,
          assets: true,
          relationships: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query, cancelToken).then(result => ({
    type,
    entities: result.search.results,
    options: {
      total: result.search.total,
      limit: result.search.limit,
      skip: result.search.skip,
    },
  })).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getEntity = ({ type, id, requestType = DEFAULT_VERSION }, cancelToken = null) => {
  const query = {
    query: {
      entity: {
        __args: {
          type,
          id,
          requestType: new EnumType(requestType),
        },
        entity: {
          id: true,
          version: true,
          title: true,
          description: true,
          type: {
            id: true,
            path: true,
          },
          fields: true,
          assets: true,
          relationships: true,
          systemStatus: {
            status: true,
            createdAt: true,
            updatedAt: true,
            approval: {
              id: true,
              requests: {
                id: true,
                revision: true,
                requestType: true,
                status: true,
                requestedBy: true,
                createdDate: true,
                message: true,
                comments: {
                  message: true,
                  commentedBy: true,
                  date: true,
                },
                updatedDate: true,
                approvedBy: true,
                approvedDate: true,
              },
            },
          },
          layers: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query, cancelToken).then(result => result.entity.entity, (err) => {
    throw err;
  }).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

/**
 * List all entities of specified type with only id and name
 * TODO: Backend to provide lighter way to list
 * @param {string} type Entity type
 */
const listSimpleEntities = (type) => {
  // parameters for search
  const parameters = [];

  parameters.push({
    operation: new EnumType('IsEqualTo'),
    field: 'type.id',
    value: type,
  });

  const query = {
    query: {
      search: {
        __args: {
          searchEntities: {
            parameters,
            limit: 100, // TODO: better way to search all
            skip: 0,
            type,
          },
        },
        results: {
          id: true,
          title: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => ({
    type,
    entities: result.search.results,
  })).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const getUser = (username) => {
  const query = {
    query: {
      user: {
        __args: {
          username,
        },
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
        groups: {
          name: true,
        },
        permissions: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.user).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const listUsers = () => {
  const query = {
    query: {
      users: {
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
        groups: {
          name: true,
        },
        permissions: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.users).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addUser = (username, email, firstName, lastName, password) => {
  const query = {
    mutation: {
      addUser: {
        __args: {
          username,
          email,
          firstName,
          lastName,
          password,
        },
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addUser).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const updateUser = (id, username, email, firstName, lastName) => {
  const query = {
    mutation: {
      updateUser: {
        __args: {
          id,
          username,
          email,
          firstName,
          lastName,
        },
        _id: true,
        username: true,
        email: true,
        firstName: true,
        lastName: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUser).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const removeUser = (id) => {
  const query = {
    mutation: {
      removeUser: {
        __args: {
          id,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.removeUser).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const resetPassword = (id, newPassword) => {
  const query = {
    mutation: {
      resetPassword: {
        __args: {
          id,
          newPassword,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.resetPassword).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const getUserGroup = (id) => {
  const query = {
    query: {
      userGroup: {
        __args: {
          id,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.userGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const addUserGroup = (name, description, externalId, userIds, permissions) => {
  const query = {
    mutation: {
      addUserGroup: {
        __args: {
          name,
          description,
          externalId,
          userIds,
          permissions,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addUserGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const updateUserGroupUserIds = (id, userIds) => {
  const query = {
    mutation: {
      updateUserGroupUserIds: {
        __args: {
          id,
          userIds,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUserGroupUserIds).catch((err) => {
    log.error(err.message);
  });
};

const updateUserGroup = (id, name, description, externalId, userIds, permissions) => {
  const query = {
    mutation: {
      updateUserGroup: {
        __args: {
          id,
          name,
          description,
          externalId,
          userIds,
          permissions,
        },
        _id: true,
        name: true,
        description: true,
        externalId: true,
        permissions: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.updateUserGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const removeUserGroup = (id) => {
  const query = {
    mutation: {
      removeUserGroup: {
        __args: {
          id,
        },
        success: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.removeUserGroup).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
  });
};

const listUserGroups = () => {
  const query = {
    query: {
      userGroups: {
        _id: true,
        name: true,
        users: {
          _id: true,
        },
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.userGroups).catch((err) => {
    // TODO: communicate failure
    log.error(err.message);
    throw err;
  });
};

const getTags = () => {
  const query = {
    query: {
      tags: {
        locale: true,
        values: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.tags).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getTagsByLocale = (locale) => {
  const query = {
    query: {
      tagsByLocale: {
        __args: {
          locale,
        },
        locale: true,
        values: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.tagsByLocale).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const addTag = (locale, tag) => {
  const query = {
    mutation: {
      addTag: {
        __args: {
          locale,
          tag,
        },
        locale: true,
        values: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.addTag).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getUploadUrl = (filename) => {
  const query = {
    query: {
      getUploadUrl: {
        __args: {
          filename,
        },
        url: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.getUploadUrl).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getDownloadUrl = (filename) => {
  const query = {
    query: {
      getDownloadUrl: {
        __args: {
          filename,
        },
        url: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.getDownloadUrl).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const getCancellationToken = () => api.getCancellationSource();

const getSavedSearches = () => {
  const query = {
    query: {
      savedSearches: {
        _id: true,
        name: true,
        sequence: true,
        type: true,
        keyword: true,
      },
    },
  };

  return api.sendQueryOrMutation(query).then(result => result.savedSearches).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const addSavedSearch = (name, sequence, type, keyword, filters, order) => {
  const query = {
    mutation: {
      addSavedSearch: {
        __args: {
          name,
          sequence,
          type,
          keyword,
          filters,
          order,
        },
        _id: true,
        name: true,
        sequence: true,
        type: true,
        keyword: true,
        filters: {
          filterType: true,
          id: true,
          filterSubfield: true,
          value: true,
          operation: true,
        },
      },
    },
  };
  return api.sendQueryOrMutation(query).then(result => result.addSavedSearch).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const updateSavedSearch = (id, name, sequence, type, keyword, filters, order) => {
  const query = {
    mutation: {
      updateSavedSearch: {
        __args: {
          id,
          name,
          sequence,
          type,
          keyword,
          filters,
          order,
        },
        _id: true,
        name: true,
        sequence: true,
        type: true,
        keyword: true,
        filters: {
          filterType: true,
          id: true,
          filterSubfield: true,
          value: true,
          operation: true,
        },
      },
    },
  };
  return api.sendQueryOrMutation(query).then(result => result.updateSavedSearch).catch((err) => {
    log.error(err.message);
    throw err;
  });
};

const removeSavedSearch = (id) => {
  const query = {
    mutation: {
      removeSavedSearch: {
        __args: {
          id,
        },
        success: true,
      },
    },
  };
  return api.sendQueryOrMutation(query).then(result => result.removeSavedSearch).catch((err) => {
    log.error(err.message);
  });
};

export {
  getCancellationToken,
  getAvailableLocales,
  searchEntities,
  getEntity,
  listSimpleEntities,
  getUser,
  listUsers,
  addUser,
  updateUser,
  resetPassword,
  removeUser,
  getUserGroup,
  addUserGroup,
  updateUserGroupUserIds,
  updateUserGroup,
  removeUserGroup,
  listUserGroups,
  getTags,
  getTagsByLocale,
  addTag,
  getUploadUrl,
  getDownloadUrl,
  getSavedSearches,
  addSavedSearch,
  updateSavedSearch,
  removeSavedSearch,
};
