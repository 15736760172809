const getAssetsFromFieldItem = item =>
  // parentEntity is injected here for later usage, quite a mom's spaghetti I must say
  Object.values(item?.assets || []).reduce((aggregate, value) => (Array.isArray(value) ? [...aggregate, ...value.map(val => ({ ...val, parentEntity: item }))] : [...aggregate, { ...value, parentEntity: item }]), []);

const getAssetsFromFieldValue = fieldValue => (Array.isArray(fieldValue) ? fieldValue.map(getAssetsFromFieldItem).flat() : getAssetsFromFieldItem(fieldValue));

const getAssetsFromEntity = (entity) => {
  const fromRelationships = Object.values(entity?.relationships || [])
    .reduce((aggregate, value) => [...aggregate, ...getAssetsFromFieldValue(value)], []);

  const fromAssets = getAssetsFromFieldItem(entity);

  return [...fromRelationships, ...fromAssets];
};


const calculateAssetsSize = (assets) => {
  const parseIntWithDefault = (value, def) => {
    const parsed = parseInt(value, 10);
    return Number.isNaN(parsed) ? def : parsed;
  };

  const calculateFullSizeReduce = (aggregate, asset) => {
    if (Array.isArray(asset)) {
      return aggregate + asset.reduce(calculateFullSizeReduce, 0);
    }
    return aggregate + parseIntWithDefault(asset.size, 0);
  };

  const calculateFullSizeInKiloBytes = assets.reduce(calculateFullSizeReduce, 0);

  const kbToMB = kb => (kb / 1000000);
  return kbToMB(calculateFullSizeInKiloBytes);
};

const calculateAssetsSizeFromEntity = (entity) => {
  const assets = getAssetsFromEntity(entity);
  return calculateAssetsSize(assets);
};

const getSelectedAssetsFromEntity = (entity, assetIds) => getAssetsFromEntity(entity).filter(asset => assetIds.includes(asset.id));

export {
  getAssetsFromEntity,
  getAssetsFromFieldItem,
  getAssetsFromFieldValue,
  getSelectedAssetsFromEntity,
  calculateAssetsSize,
  calculateAssetsSizeFromEntity,
};
