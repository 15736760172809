<template>
  <div class="image-thumbnail-wrapper">
    <b-img :src="imageSource" :id="id" :class="`image-thumbnail ${extraClasses}`" :alt="altText"/>
    <b-popover :target="id" triggers="hover" :placement="placement">
      <img :src="imageSource" class="image-hover-preview" />
    </b-popover>
  </div>
</template>

<script>
export default {
  name: 'PopoverImage',

  props: {
    data: {},
    id: {
      type: String,
      required: true,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      required: false,
    },
    height: {
      type: String,
      required: false,
    },
    format: {
      type: String,
      required: false,
    },
    placement: {
      type: String,
      required: false,
      default: 'topleft',
    },
    extraClasses: {
      type: String,
      required: false,
    },
    altText: {
      type: String,
      required: false,
    },
  },
  computed: {
    imageSource() {
      const url = new URL(this.baseUrl, window.location); // window.location is needed to determine correct protocol

      if (this.width) {
        url.searchParams.append('w', this.width);
      }

      if (this.height) {
        url.searchParams.append('h', this.height);
      }

      if (this.format) {
        url.searchParams.append('fm', this.format);
      }

      return url.toString();
    },
  },
};
</script>
