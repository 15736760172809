import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getAssetsFromEntity, getSelectedAssetsFromEntity } from '@/common/utils/AssetUtils';
import { createFileName, createFileNameWithTimestamp } from '@/common/utils/FileUtils';
import store from '@/store';

const DEFAULT_LOCALE = store.getters.defaultLocale;

const generateZip = (assets, statusCallback) => {
  const generateAssetFileName = (asset) => {
    console.debug('DownloadUtils.generateAssetFileName');
    const title = asset.title[DEFAULT_LOCALE] || asset.id;
    const fileName = asset.url.split('/').pop();
    const fileExt = fileName.includes('.') ? fileName.split('.').pop() : asset.mediaType.split('/').pop();

    return createFileName(title, fileExt);
  };

  // TODO: täällä lataillaan filejä selaimen muistiin
  const downloadFiles = (urls) => {
    console.debug('DownloadUtils.downloadFiles');

    let downloadCount = 0;

    const updateProgressBar = () => {
      if (statusCallback) {
        const percentageDownloaded = ((downloadCount / urls.length) * 100).toFixed(0);
        statusCallback('download', percentageDownloaded);
      }
    };

    // initial status
    updateProgressBar();

    const downloadAsBlob = url =>
      fetch(url)
        .then(resp => resp.blob())
        .then((blob) => {
          downloadCount += 1;
          updateProgressBar();
          return blob;
        });

    return Promise.all(urls.map(downloadAsBlob));
  };

  const mapBlobsAndAssets = blobs => blobs.map((blob, i) => ({ blob, asset: assets[i] }));

  const generateZipFile = (dataArray) => {
    console.debug('DownloadUtils.generateZipFile');
    const zip = new JSZip();


    const updateProgressBar = (percentageCompleted) => {
      if (statusCallback) {
        statusCallback('zip', percentageCompleted);
      }
    };

    // initial status
    updateProgressBar(0);

    dataArray.forEach((data) => { zip.file(generateAssetFileName(data.asset), data.blob); });

    return zip.generateAsync({ type: 'blob' }, (metadata) => {
      updateProgressBar(metadata.percent.toFixed(0));
    });
  };

  const generateZipFileFromAssets = () => {
    console.debug('DownloadUtils.generateZipFileFromAssets');
    const urls = assets.map(asset => asset.url);

    return downloadFiles(urls)
      .then(mapBlobsAndAssets)
      .then(generateZipFile);
  };

  console.debug('Start ZIP generation');
  return generateZipFileFromAssets();
};

const saveFile = (content, filename) => {
  console.debug('DownloadUtils.saveFile');
  return saveAs(content, filename);
};

const generateZipFileFromEntities = (entities, statusCallback) => {
  console.debug('DownloadUtils.generateZipFileFromEntities');
  const assets = entities.map(getAssetsFromEntity).flat();

  return generateZip(assets, statusCallback);
};

const downloadAssets = (zipTitle, assets, statusCallback) => {
  console.debug('DownloadUtils.downloadAssets');
  const fileName = createFileName(zipTitle, 'zip');

  return generateZip(assets, statusCallback).then(res => saveFile(res, fileName));
};

const downloadItem = (entity, statusCallback) => {
  console.debug(`DownloadUtils.downloadItem: Downloading assets for entity ${entity.id}`);

  const title = entity.title[DEFAULT_LOCALE];
  const assets = getAssetsFromEntity(entity);

  return downloadAssets(title, assets, statusCallback);
};

const downloadItems = (entities, statusCallback) => {
  console.debug(`DownloadUtils.downloadItems: Downloading assets from ${entities.length} entities`);

  const title = createFileNameWithTimestamp('MediaPackage');
  const assets = entities.map(getAssetsFromEntity).flat();

  return downloadAssets(title, assets, statusCallback);
};

const downloadSelectedAssets = (entity, selectedAssetIds, statusCallback) => {
  console.debug(`DownloadUtils.downloadSelectedAssets: Downloading selected assets... ${JSON.stringify(selectedAssetIds)}`);

  const title = entity.title[DEFAULT_LOCALE];
  const assets = getSelectedAssetsFromEntity(entity, selectedAssetIds);

  return downloadAssets(title, assets, statusCallback);
};

export {
  downloadItem,
  downloadItems,
  downloadSelectedAssets,
  generateZipFileFromEntities,
};
