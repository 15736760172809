<template>
  <div class="entity-list">
    <div class="loading-wrapper" v-show="loading">
        <div class="spinner-content-wrapper">
          <spinner class="spinner"/>
          <slot name="cancel-action"></slot>
        </div>
    </div>
    <b-table show-empty fixed stacked="lg" :hover="hover" :fields="fields" :items="entityList.entities" :sort-by="sortBy" @row-clicked="rowClicked">
      <template v-slot:cell(assets.image)="row">
        <popover-image v-if="row.item.assets && row.item.assets.image && row.item.assets.image.url"
          :id="`${row.item.assets.image.id}-${row.index}`" width="500" height="500"
          :baseUrl="resolveImageBaseUrl(row.item.assets.image)" :format="isTiffImage(row.item.assets.image) ? 'jpg' : ''" />
      </template>
      <template v-slot:cell(actions)="row">
        <slot name="actions" v-bind:row="row"></slot>
      </template>
    </b-table>
    <b-row v-if="!disablePagination">
      <b-col md="12" class="my-1 text-center" v-if="displayPagination && entityList.entities.length > 0">
        <div class="col-12">
          <b-btn @click="pageChange" class="w-100">{{ $t('search.loadMore') }} (+{{ this.entityList.options.limit }})</b-btn>
        </div>
        <!-- Load all, let's not load all if type 'any' is selected, as this would take forever -->
        <span class="p-1">
          <a @click="loadAll" class="load-all">{{ $t('search.loadAll') }} ({{ entityList.options.total }}) <span v-if="entityList.options.total > 500">({{ $t('search.lotsOfResults') }})</span> </a>
        </span>
      </b-col>
      <b-col v-else md="12" class="mb-5 mt-2 text-center">
        <span class="load-all">{{ $t('search.allLoaded') }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import PopoverImage from './PopoverImage.vue';

export default {
  name: 'EntityList',
  components: { Spinner, PopoverImage },
  props: {
    entityList: {
      required: true,
    },
    fields: {
      required: true,
    },
    loading: {
      required: false,
    },
    sortBy: {
      default: null,
    },
    hover: {
      default: false,
    },
    disablePagination: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  watch: {
    entityList() {
      this.allEntitiesLoadedCheck();
    },
  },
  data() {
    return {
      currentPage: 1,
      displayPagination: true,
      allEntitiesLoaded: false,
    };
  },
  methods: {
    mounted() {
      this.allEntitiesLoadedCheck();
    },
    allEntitiesLoadedCheck() {
      if (this.entityList.options.total === this.entityList.entities.length) {
        // Hide load more results if all entities are visible
        this.displayPagination = false;
        this.allEntitiesLoaded = true;
      } else {
        this.displayPagination = true;
      }
    },
    pageChange() {
      this.currentPage += 1;
      this.$emit('pageChange', this.currentPage);
    },
    loadAll() {
      this.$emit('loadAll');
    },
    resetPagination() {
      this.currentPage = 1;
    },
    rowClicked(item) {
      this.$emit('rowClicked', item);
    },
    setPaginationToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    resolveImageBaseUrl(asset) {
      if (this.isTiffImage(asset)) {
        return asset.url.replace('downloads.ctfassets.net', 'images.ctfassets.net');
      }

      // default
      return asset.url;
    },
    isTiffImage(asset) {
      return asset.mediaType === 'image/tiff';
    },
  },
};
</script>

<style lang="scss">
.entity-list {
  .load-all {
    font-size: 12px;
    cursor: pointer;
  }
}
</style>
