import fecha from 'fecha';

// default mask it set in main.js
const formatDate = dateString => fecha.format(Date.parse(dateString), 'default');

const customFormatDate = (date, format) => fecha.format(date, format);

const getCurrentDateWithCustomFormat = format => customFormatDate(new Date(), format);

export {
  formatDate,
  customFormatDate,
  getCurrentDateWithCustomFormat,
};
