<template>
  <div>
    <b-nav v-if="!$route.meta || !$route.meta.loginLayout" vertical class="w-100">
      <div v-for="(route, i) in routes" :key="`route-${i}`">
        <span v-if="route.meta && route.meta.subHeader && route.hasPermissions" class="sub-header">{{ route.meta.subHeader }}</span>
        <b-nav-item v-if="
          route.hasPermissions
          && route.meta
          && route.meta.title
          && !route.meta.hidden
          "
          :to="route"
          class="mt-1"
          exact>
          <font-awesome-icon :icon="route.icon || 'align-justify'" />
          <span class="menu-title">{{ route.meta.title }}</span>
        </b-nav-item>
      </div>
    </b-nav>
    <b-nav v-else vertical class="w-100">
      <b-nav-item :active="true">
        <font-awesome-icon icon="key" />
        <span class="menu-title">{{ $t('login.login') }}</span>
      </b-nav-item>
    </b-nav>
  </div>
</template>

<script>

export default {
  name: 'MainMenu',
  props: ['permissions', 'savedSearches'],
  computed: {
    routes() {
      const routes = [];
      this.$router.options.routes.forEach((route) => {
        // dynamically build entity navigation links based on type definitions and user's access rights
        // default logic
        routes.push(route);

        if (route.menuItems) {
          route.menuItems.forEach((item) => {
            routes.push({
              name: route.name,
              params: item.params,
              meta: item.meta,
              icon: item.icon,
            });
          });
        }
      });

      if (this.savedSearches?.length > 0) {
        routes.push({ // Space separator to navigation tree
          path: '',
          meta: {
            subHeader: 'Searches',
          },
        });

        this.savedSearches.forEach((item) => {
          routes.push({
            name: 'search',
            query: {
              type: item.type,
              keyword: item.keyword,
            },
            meta: {
              title: item.name,
              resetStack: true,
              permission: {
                target: 'type',
                value: item.type,
              },
            },
            icon: 'cube',
          });
        });
      }

      // add permissions flag
      routes.forEach((route) => { route.hasPermissions = this.hasReadOrWritePermissions(route); });

      return routes;
    },
  },
  methods: {
    hasReadOrWritePermissions(route) {
      let hasPermission = false;

      // for subheaders, check possible given child permissions (see localization)
      if (route?.meta?.subHeader) {
        const hasChildPermissionsToCheck = !!route?.meta?.childPermissions;

        if (!hasChildPermissionsToCheck) {
          hasPermission = true;
        } else if (this.permissions) {
          hasPermission = route.meta.childPermissions.some(permission => this.permissions.grantsReadOrWriteAccess(permission.target, permission.value));
        }
      } else {
        const routeDoesNotHaveAnyPermissions = !route?.meta?.permission;

        const userHasPermissionsToRoute = (
          !!this.permissions &&
          !!route?.meta?.permission?.target &&
          !!route?.meta?.permission?.value &&
          this.permissions.grantsReadOrWriteAccess(route.meta.permission.target, route.meta.permission.value)
        );


        hasPermission = routeDoesNotHaveAnyPermissions || userHasPermissionsToRoute;
      }

      return hasPermission;
    },
  },
};
</script>
