import {
  faAlignJustify,
  faCalendar,
  faCalendarPlus,
  faCheck,
  faCoffee,
  faCube,
  faCubes,
  faExclamationTriangle,
  faGlobeAmericas,
  faHome,
  faImage,
  faImages,
  faInfoCircle,
  faKey,
  faLock,
  faNewspaper,
  faPen,
  faPlus,
  faQuestionCircle,
  faSearch,
  faTimes,
  faTv,
  faUndo,
  faUser,
  faTrash,
  faArrowUp,
  faArrowDown,
  faFile,
  faFileVideo,
  faFileAudio,
  faFilePdf,
  faShoppingBasket,
} from '@fortawesome/free-solid-svg-icons';

export default {
  defaultOptions: {
    defaultLocale: 'en-US',
    locale: 'en-US',
  },
  availableLocales: ['en-US', 'fi-FI', 'es-ES', 'sv-SE', 'ru-RU'],
  api: {
    defaultVersion: 'Published', // Latest = Preview, Published = Delivery, Management = Management
  },
  permissions: {
    availablePermissions: [
      {
        target: {
          value: 'type',
          text: 'Type',
        },
        values: [
          {
            value: 'audioContent',
            text: 'Audio Content',
          },
          {
            value: 'document',
            text: 'Document',
          },
          {
            value: 'image',
            text: 'Image',
          },
          {
            value: 'video',
            text: 'Video',
          },
        ],
        accessModes: ['read'],
      },
      {
        target: {
          value: 'admin',
          text: 'Admin',
        },
        values: [
          {
            value: 'user',
            text: 'User',
          },
          {
            value: 'userGroup',
            text: 'User group',
          },
        ],
        accessModes: ['read', 'write'],
      },
      {
        target: {
          value: 'version',
          text: 'Version',
        },
        values: [
          {
            value: 'Latest',
            text: 'Latest',
          },
        ],
        accessModes: ['read'],
      },
      {
        target: {
          value: 'usageRights',
          text: 'Usage rights',
        },
        values: [
          {
            value: 'Genelec Restricted',
            text: 'Genelec Restricted',
          },
          {
            value: 'Genelec Personnel',
            text: 'Genelec Personnel',
          },
          {
            value: 'Distributor Pro',
            text: 'Distributor Pro',
          },
          {
            value: 'Distributor AV',
            text: 'Distributor AV',
          },
          {
            value: 'Distributor Home Audio',
            text: 'Distributor Home Audio',
          },
          {
            value: 'Dealer Pro',
            text: 'Dealer Pro',
          },
          {
            value: 'Dealer AV',
            text: 'Dealer AV',
          },
          {
            value: 'Dealer Home Audio',
            text: 'Dealer Home Audio',
          },
          {
            value: 'Media',
            text: 'Media',
          },
          {
            value: 'Public',
            text: 'Public',
          },
        ],
        accessModes: ['read'],
      },
      {
        target: {
          value: 'date',
          text: 'Date',
        },
        values: [
          {
            value: 'allow',
            text: 'Allow data outside of availability dates',
          },
        ],
        accessModes: ['read'],
      },
      {
        target: {
          value: 'mediaBasket',
          text: 'Media basket',
        },
        values: [
          {
            value: 'allow',
            text: 'Allow user to make and share media baskets',
          },
        ],
        accessModes: ['write'],
      },
    ],
    newPermissionDefault: '*',
    defaultPermissionGroup: 'DefaultPermissions',
  },
  hiddenFields: [
    {
      type: 'mediaPackage',
      fields: ['internalName', 'usageRights', 'availableForDownloadFrom', 'tags'],
    },
  ],
  guest: {
    username: process.env.VUE_APP_GUEST_USERNAME || 'guest',
    password: process.env.VUE_APP_GUEST_PASSWORD || 'pass1234',
  },
  proxy: (typeof window !== 'undefined') ? `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/graphql` : null,
  authEndpoint: (typeof window !== 'undefined') ? `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}` : null,
  authenticationMethods: ['local', 'guest'],
  typesSearchedFromAnyField: ['any', 'audioContent', 'document', 'image', 'mediaPackage', 'video'],
  typesAvailableForSearch: ['audioContent', 'document', 'image', 'mediaPackage', 'video'],
  mediaPackageSearchBatchSize: 100,
  mediaSearchBatchSize: 100,
  icons: [
    faAlignJustify,
    faCalendar,
    faCalendarPlus,
    faCheck,
    faCoffee,
    faCube,
    faCubes,
    faExclamationTriangle,
    faGlobeAmericas,
    faHome,
    faImage,
    faImages,
    faInfoCircle,
    faKey,
    faLock,
    faNewspaper,
    faPen,
    faPlus,
    faQuestionCircle,
    faSearch,
    faTimes,
    faTv,
    faUndo,
    faUser,
    faTrash,
    faArrowUp,
    faArrowDown,
    faFile,
    faFileVideo,
    faFileAudio,
    faFilePdf,
    faShoppingBasket,
  ],
};
