import { formatDate } from '@/common/utils/DateUtils';
import { calculateAssetsSizeFromEntity } from '@/common/utils/AssetUtils';
import { marked } from 'marked';

export default {
  methods: {
    formatDate(datestring) {
      return formatDate(datestring);
    },
    translateType(type) {
      return this.$t(`entityTypes.${type}`);
    },
    formatField(value) {
      if (!value) {
        return '';
      }
      if (Array.isArray(value)) {
        let itemsString = '';
        const amountListed = 5;
        const amountNotListed = value.length - amountListed;

        value.some((arrayValue, i) => {
          if (arrayValue.title && arrayValue.title[this.locale]) {
            itemsString = `${itemsString} ${arrayValue.title[this.locale]}`;
          }
          return i === amountListed - 1;
        });

        return amountNotListed <= 0 ? itemsString : `${itemsString} ... ${this.$t('common.and')} ${amountNotListed} ${this.$t('common.more')}!`;
      }
      if (typeof value === 'string') {
        return value.length > 200 ? `${value.substring(0, 200)}...` : value; // First 200 chars
      }
      if (typeof value === 'object') {
        return value.title[this.locale];
      }

      return value;
    },
    formatAssetSize(value, key, item) {
      const megabytes = calculateAssetsSizeFromEntity(item);
      return megabytes.toFixed(2);
    },
    formatMarkdown(value) {
      return (value) ? marked.parse(value) : value;
    },
  },
};
