<template>
  <div class="login">
    <h1>{{ $t('login.loginHeader') }}</h1>
    <b-alert :show="(message !== '' || externalMessage !== '')"
             variant="danger">
      {{ message || externalMessage }}
    </b-alert>
    <div class="form-container mt-5 w-100 d-flex justify-content-left">
      <b-col v-if="isLoginMethodEnabled('sso')" col lg="8" xl="6" md="12">
        <h2>{{ $t('login.sso.title') }}</h2>
        <b-form class="bg-light p-5">
          <b-form-group>
            <p>{{ $t('login.sso.text') }}</p>
            <b-button href="/auth/openid" size="lg">{{ $t('login.login') }}</b-button>
          </b-form-group>
        </b-form>
      </b-col>
      <b-col v-if="isLoginMethodEnabled('local')" col lg="8" xl="6" md="12">
        <h2>{{ $t('login.local.title') }}</h2>
        <b-form class="bg-light p-5">
          <b-form-group>
            <b-row class="mb-3">
              <b-col md="2">
                <label for="username">{{ $t('login.username') }}</label>
              </b-col>
              <b-col md="10">
                <b-form-input id="username"
                              v-model="username"
                              type="text">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="2">
                <label for="password">{{ $t('login.password') }}</label>
              </b-col>
              <b-col md="10">
                <b-form-input id="password"
                              @keyup.enter.native="login()"
                              v-model="password"
                              type="password">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-3">
              <b-col md="12">
                <b-button size="lg" @click.stop="login()">
                  {{ $t('login.login') }}
                </b-button>
              </b-col>
            </b-row>
            <b-row v-if="isLoginMethodEnabled('guest')" class="text-center">
              <b-col md="12">
                <a href="#" @click.prevent="loginGuest">{{ $t('login.asGuest') }}</a>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
      </b-col>
    </div>
  </div>
</template>

<script>
import config from '@/config';

export default {
  name: 'login',
  props: {
    externalMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      username: '',
      password: '',
      message: '',
    };
  },
  methods: {
    login() {
      this.message = '';
      this.$store.dispatch('loginLocal', { username: this.username, password: this.password })
        .then(() => this.$router.push({ name: 'home' }))
        .catch(() => { this.message = this.$t('login.loginFailed'); });
    },
    loginGuest() {
      this.$store.dispatch('loginGuest')
        .then(() => this.$router.push({ name: 'home' }))
        .catch(() => { this.message = this.$t('login.loginFailed'); });
    },
    isLoginMethodEnabled(method) {
      return config.authenticationMethods.indexOf(method) !== -1;
    },
  },
};

</script>
