const createFileName = (fileName, fileExtension) => {
  const parsedNamed = `${fileName.replaceAll(' ', '_').replaceAll('/', '_')}`;

  // Add file extension if given, otherwise return as is
  return fileExtension ? `${parsedNamed}.${fileExtension}` : parsedNamed;
};

const createFileNameWithTimestamp = (fileName, fileExtension) => {
  const [date, time] = (new Date()).toISOString().split('T');
  const title = `${fileName}_${date}_${time.replaceAll(':', '-').split('.')[0]}`;

  return createFileName(title, fileExtension);
};

export {
  createFileName,
  createFileNameWithTimestamp,
};
