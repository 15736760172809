import Vue from 'vue';
import Vuex from 'vuex';
import VuexI18n from 'vuex-i18n';
import { set } from 'lodash';
import createPersistedState from 'vuex-persistedstate';
import GraphqlApi from './api/GraphqlApi';
import AuthApi from './api/AuthApi';
import auth from './auth';
import router from './router';
import PermissionList from './types/PermissionList';
import { getTags, addTag as apolloAddTag, getSavedSearches, addSavedSearch as apolloAddSavedSearch, updateSavedSearch as apolloUpdateSavedSearch, removeSavedSearch as apolloRemoveSavedSearch } from './api/apolloQueries';

import config from './config';
import log from './browserlog';

// languages
import enUS from './i18n/en-US.json';

const api = new GraphqlApi(config.proxy);
const authApi = new AuthApi(config.authEndpoint);

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    model: {},
    typeDefs: [],
    layeringSettings: {},
    activeLocales: [],
    tags: [],
    options: {
      ...config.defaultOptions,
    },
    apiVersion: config.api.defaultVersion,
    user: {},
    stack: [],
    mediaBasket: [],
    savedSearches: [],
  },
  plugins: [
    createPersistedState({
      paths: ['mediaBasket'],
      storage: sessionStorage,
    }),
  ],
  getters: {
    defaultLocale: state => state.options.defaultLocale,
    locale: state => state?.options?.locale || state?.options?.defaultLocale,
    availableLocales: state => state.activeLocales,
    tags: state => state.tags,
    userPermissions: state => state.user.permissions,
    apiVersion: state => state.apiVersion,
    layers: state => state.layeringSettings.layers || [],
    previousStackItem: state => state.stack[state.stack.length - 2],
    lastStackItem: state => state.stack[state.stack.length - 1],
    userPerspectivePreferences: state => state.user.perspectivePreferences,
    mediaBasket: state => state.mediaBasket,
    savedSearches: state => state.savedSearches,
  },
  mutations: {
    SET_MODEL_VALUE(state, { fieldId, newValue }) {
      const freshModel = {
        ...state.model,
      };
      set(freshModel, fieldId, newValue);
      state.model = freshModel;
    },
    SET_TYPEDEFS(state, typeDefs) {
      state.typeDefs = typeDefs;
    },
    SET_AVAILABLE_LOCALES(state, locales) {
      state.activeLocales = locales;
    },
    SET_TAGS(state, tags) {
      state.tags = tags;
    },
    SET_API_VERSION(state, apiVersion) {
      state.apiVersion = apiVersion;
    },
    SET_LAYERING_SETTINGS(state, layeringSettings) {
      state.layeringSettings = layeringSettings;
    },
    RESET_MODEL(state) {
      state.model = {};
    },
    SET_LOCALE(state, locale) {
      state.options.locale = locale;
    },
    SET_USER(state, user) {
      state.user = user;

      if (user.permissions) {
        const userPerms = new PermissionList();
        userPerms.parseFromStringArray(user.permissions);
        state.user.permissions = userPerms;
      }
    },
    ADD_TO_STACK(state, route) {
      state.stack.push(route);
    },
    REPLACE_STACK(state, route) {
      state.stack = [route];
    },
    EMPTY_STACK(state) {
      state.stack = [];
    },
    SLICE_STACK(state, index) {
      state.stack = state.stack.slice(0, index + 1);
    },
    REMOVE_LAST_FROM_STACK(state) {
      state.stack = state.stack.slice(0, state.stack.length - 1);
    },
    SET_LAST_STACK_ITEM_TITLE(state, title) {
      if (state.stack[state.stack.length - 1]) {
        Vue.set(state.stack, state.stack.length - 1, {
          ...state.stack[state.stack.length - 1],
          title,
        });
      }
    },
    SET_USER_PERSPECTIVE_PREFERENCES(state, perspectivePreferences) {
      state.user.perspectivePreferences = perspectivePreferences;
    },
    ADD_TO_MEDIA_BASKET(state, items) {
      const mediaBasketCopy = [...state.mediaBasket].concat(items);
      const filteredBasket = mediaBasketCopy.reduce((uniqArray, item) => (uniqArray.some(e => e.id === item.id) ? uniqArray : [...uniqArray, item]), []);

      state.mediaBasket = filteredBasket;
    },
    REMOVE_FROM_MEDIA_BASKET(state, id) {
      const copy = [...state.mediaBasket];
      const index = copy.findIndex(e => e.id === id);
      if (index > -1) {
        copy.splice(index, 1);
        state.mediaBasket = copy;
      }
    },
    EMPTY_MEDIA_BASKET(state) {
      state.mediaBasket = [];
    },
    SET_SAVED_SEARCHES(state, savedSearches) {
      savedSearches.sort((a, b) => a.sequence - b.sequence);
      state.savedSearches = savedSearches;
    },
  },
  actions: {
    setLocale({ commit }, locale) {
      commit('SET_LOCALE', locale);
    },
    modelChanged({ commit }, { fieldId, newValue }) {
      commit('SET_MODEL_VALUE', { fieldId, newValue });
    },
    getTypeDefs({ commit }) {
      const query = {
        query: {
          typeDefinitions: {
            id: true,
            name: true,
            titleField: true,
            access: true,
            fields: {
              id: true,
              name: true,
              localized: true,
              type: true,
              required: true,
              precision: true,
              formatPattern: true,
              validations: true,
              listOf: true,
              options: {
                appearance: true,
                helpText: true,
                settings: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        const sortedDefs = Array.isArray(result.typeDefinitions)
          ? result.typeDefinitions.sort((a, b) => {
            if (a.id < b.id) { return -1; }
            if (a.id > b.id) { return 1; }
            return 0;
          })
          : result.typeDefinitions;

        commit('SET_TYPEDEFS', sortedDefs);
        return result;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    getAvailableLocales({ commit }) {
      // TODO: read from cms database...or make API for it...or is this needed even?
      commit('SET_AVAILABLE_LOCALES', config.availableLocales);
      return config.availableLocales;
    },
    getTags({ commit }) {
      // TODO: Duplicate tags to mediabank mongoDB OR Read from CMS mongoDB OR Make API to CMS for reading them?
      return getTags().then((result) => {
        log.debug(`Tags: ${JSON.stringify(result)}`);
        commit('SET_TAGS', result);
        return result;
      }).catch((err) => {
        log.error(`Error while fetching tags: ${err}`);
      });
    },
    addTag({ commit }, { locale, tag }) {
      // This part of code was previously unused and faulty
      // I replaced the faulty recursive call with backend call: this might not be correct
      apolloAddTag(locale, tag).then((result) => {
        const tagsCopy = [...this.state.tags];
        const index = tagsCopy.findIndex(e => e.locale === locale) || [];
        tagsCopy.splice(index, 1, result);

        commit('SET_TAGS', tagsCopy);
      }).catch((err) => {
        log.error(`Error while adding tag: ${tag} to locale: ${locale}. Error: ${err}`);
      });
    },
    getLayeringSettings({ commit }) {
      const query = {
        query: {
          layeringSettings: {
            field: true,
            layers: {
              id: true,
              order: true,
              title: true,
              layers: {
                id: true,
                order: true,
                title: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        log.debug('Layering settings', result);
        commit('SET_LAYERING_SETTINGS', result.layeringSettings);
        return result;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    getCurrentUser({ commit }) {
      const query = {
        query: {
          currentUser: {
            _id: true,
            email: true,
            firstName: true,
            lastName: true,
            username: true,
            groups: {
              name: true,
            },
            permissions: true,
            savedSearches: {
              _id: true,
              name: true,
              keyword: true,
              type: true,
              filters: {
                filterType: true,
                id: true,
                filterSubfield: true,
                value: true,
                operation: true,
              },
            },
            perspectivePreferences: {
              columnMode: true,
              defaultSettings: {
                perspective: {
                  mainLayer: true,
                  subLayer: true,
                },
                locale: true,
              },
              localizationSettings: {
                perspective: {
                  mainLayer: true,
                  subLayer: true,
                },
                locales: true,
              },
              cdjSettings: {
                perspectives: {
                  mainLayer: true,
                  subLayer: true,
                },
                locale: true,
              },
            },
          },
        },
      };

      return api.sendQueryOrMutation(query).then((result) => {
        commit('SET_USER', result.currentUser);
        return result.currentUser;
      }).catch((err) => {
        // TODO: communicate failure
        log.error(err.message);
      });
    },
    init({ dispatch }) {
      const typeDefs = dispatch('getTypeDefs');
      const layeringSettings = dispatch('getLayeringSettings');
      const getCurrentUser = dispatch('getCurrentUser');
      const availableLocales = dispatch('getAvailableLocales');
      const tags = dispatch('getTags');
      const savedSearches = dispatch('getSavedSearches');

      return Promise.all([typeDefs, layeringSettings, getCurrentUser, availableLocales, tags, savedSearches]).then(() => true);
    },
    login({ dispatch }, token) {
      auth.setToken(token);
      dispatch('init');
    },
    logout({ commit }) {
      auth.removeToken();
      commit('SET_USER', {});
      commit('EMPTY_MEDIA_BASKET');
      router.push({ name: 'login' });
    },
    loginLocal({ dispatch }, { username, password }) {
      return new Promise((resolve, reject) => {
        authApi.loginLocal(username, password)
          .then((result) => {
            dispatch('login', result.token);
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    loginGuest({ dispatch }) {
      return dispatch('loginLocal', { username: config.guest.username, password: config.guest.password });
    },
    addToStack({ commit }, route) {
      commit('ADD_TO_STACK', route);
    },
    replaceStack({ commit }, route) {
      commit('REPLACE_STACK', route);
    },
    emptyStack({ commit }) {
      commit('EMPTY_STACK');
    },
    sliceStack({ commit }, index) {
      commit('SLICE_STACK', index);
    },
    removeLastFromStack({ commit }) {
      commit('REMOVE_LAST_FROM_STACK');
    },
    setApiVersion({ commit }, apiVersion) {
      commit('SET_API_VERSION', apiVersion);
    },
    addToMediaBasket({ commit }, items) {
      commit('ADD_TO_MEDIA_BASKET', items);
    },
    removeFromMediaBasket({ commit }, itemId) {
      commit('REMOVE_FROM_MEDIA_BASKET', itemId);
    },
    emptyMediaBasket({ commit }) {
      commit('EMPTY_MEDIA_BASKET');
    },
    getSavedSearches({ dispatch }) {
      return getSavedSearches().then((result) => {
        log.debug(`Saved searches: ${JSON.stringify(result)}`);
        dispatch('setSavedSearches', result);
        return result;
      }).catch((err) => {
        log.error(`Error while fetching saved searches: ${err}`);
      });
    },
    setSavedSearches({ commit }, savedSearches) {
      commit('SET_SAVED_SEARCHES', savedSearches);
    },
    addSavedSearch({ dispatch }, { name, sequence, type, keyword }) {
      return apolloAddSavedSearch(name, sequence, type, keyword, null, null).then((result) => {
        const searchesCopy = [...this.state.savedSearches, result];

        dispatch('setSavedSearches', searchesCopy);

        return result;
      }).catch((err) => {
        log.error(`Error while saving search with name: Name: ${name}. Error: ${err}`);
        throw err;
      });
    },
    updateSavedSearch({ dispatch }, { id, name, sequence, type, keyword }) {
      return apolloUpdateSavedSearch(id, name, sequence, type, keyword, null, null).then((result) => {
        const searchesCopy = [...this.state.savedSearches];

        const index = searchesCopy.findIndex(x => x._id === id);

        if (index >= 0) {
          searchesCopy.splice(index, 1, result);
          dispatch('setSavedSearches', searchesCopy);
        }

        return result;
      }).catch((err) => {
        log.error(`Error while updating search with id: ${id}. Error: ${err}`);
        throw err;
      });
    },
    removeSavedSearch({ dispatch }, id) {
      return apolloRemoveSavedSearch(id).then(() => {
        const searchesCopy = this.state.savedSearches;
        const index = searchesCopy.findIndex(x => x._id === id);

        if (index >= 0) {
          searchesCopy.splice(index, 1);
          dispatch('setSavedSearches', searchesCopy);
        }
      }).catch((err) => {
        log.error(`Error while removing search with id: ${id}. Error: ${err}`);
        throw err;
      });
    },
  },
});

// i18n
Vue.use(VuexI18n.plugin, store);
Vue.i18n.add('en-US', enUS);
Vue.i18n.set(store.getters.locale);

export default store;
