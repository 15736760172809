<template>
  <div>
    <div class="loading-wrapper-modal" v-show="downloading">
      <div class="spinner-content-wrapper">
        <spinner class="spinner"/>
        <progress-bar ref="progressBar" />
      </div>
    </div>
    <div class="home">
      <h1>{{ $t('home.welcome') }}</h1>
      <div class="dashboard-box">
        <div class="mt-1 mb-3 dashboard-box-title">{{ $t('home.latestMediaPackages') }}</div>
        <entity-list
            :entityList="mediaPackages"
            :fields="mediaPackagesColumns"
            :loading="loadingMediaPackages"
            :hover="true"
            @pageChange="loadLatestMediaPackages"
            @loadAll="loadAll"
            @rowClicked="forwardToPage">
          <template slot="actions" slot-scope="row">
            <slot name="actions" v-bind:row="row.row">
              <b-button size="sm" @click.stop="download(row.row.item)">
                {{ $t('button.download') }}
              </b-button>
            </slot>
          </template>
        </entity-list>
      </div>
    </div>
  </div>
</template>

<script>
import EntityList from '@/components/EntityList.vue';
import { searchEntities } from '@/api/apolloQueries';
import { mapGetters } from 'vuex';
import { downloadItem } from '@/common/utils/DownloadUtils';
import Spinner from 'vue-spinner-component/src/Spinner.vue';
import ProgressBar from '@/components/ProgressBar.vue';
import FieldFormatter from '@/common/mixins/FieldFormatter';

export default {
  name: 'home',
  components: { EntityList, Spinner, ProgressBar },
  mixins: [FieldFormatter],
  data() {
    return {
      currentPage: 0,
      mediaPackages: {
        type: 'mediaPackage',
        entities: [],
        options: {
          limit: 10,
          total: 0,
        },
      },
      loadingMediaPackages: false,
      downloading: false,
    };
  },
  computed: {
    ...mapGetters(['locale', 'userPermissions']),
    mediaPackagesColumns() {
      return [
        {
          key: 'fields.title',
          label: this.$t('entity.title'),
          formatter: this.formatField,
        },
        {
          key: 'systemStatus.updatedAt',
          label: this.$t('entity.updatedAt'),
          formatter: this.formatDate,
        },
        {
          key: 'assetSize', // this ist just a placeholder, formatAssetSize return correct value
          label: this.$t('entity.assetSizeMB'),
          formatter: this.formatAssetSize,
        },
        {
          key: 'actions',
          label: this.$t('search.actions'),
        },
      ];
    },
    getProgressBarDownloadHandler() {
      return this.$refs.progressBar.updateProgressBarBownload;
    },
  },
  methods: {
    getStatusString(status) {
      return this.$t(`entityStatus.${status.toLowerCase()}`);
    },
    loadLatestMediaPackages() {
      this.loadingMediaPackages = true;
      const skip = this.mediaPackages.options.limit * this.currentPage;

      searchEntities({
        type: this.mediaPackages.type,
        skip,
        limit: this.mediaPackages.options.limit,
        order: {
          reverseOrderBy: 'systemStatus.updatedAt',
        },
      }).then((results) => {
        this.currentPage += 1;
        const combinedEntityResults = { entities: [...this.mediaPackages.entities, ...results.entities] };
        this.mediaPackages = {...results, ...combinedEntityResults };
      }).finally(() => {
        this.loadingMediaPackages = false;
      });
    },
    loadAll() {
      this.loadingMediaPackages = true;
      
      searchEntities({
        type: this.mediaPackages.type,
        skip: 0,
        limit: this.mediaPackages.options.total,
        order: {
          reverseOrderBy: 'systemStatus.updatedAt',
        },
      }).then((results) => {
        this.mediaPackages = results;
      }).finally(() => {
        this.loadingMediaPackages = false;
      });
    },
    download(item) {
      this.downloading = true;
      downloadItem(item, this.getProgressBarDownloadHandler).finally(() => { this.downloading = false; });
    },
    forwardToPage(item) {
      this.$router.push({ name: 'media-package', params: { id: item.id } });
    },
  },
  beforeMount() {
    this.loadLatestMediaPackages();
  },
};
</script>
